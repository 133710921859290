.container {
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }
  
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    flex-direction: column;
    gap: 4px;
  }
  
  img {
    /* Add any necessary styles for your spinner here */
  }
  
  h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #3182CE;
  }